import type { ComputedRef } from 'vue'

export default function useCustomStyles(styles: ComputedRef<string>, id?: string) {
  const componentId = id || useId()
  const computedStyles = computed((): string => {
    if (!styles.value || String(styles.value || '').trim().length === 0) {
      return ''
    }
    return parseCustomCss(wrapString(styles.value, `#${componentId}{`, '}'))
  })

  useHead({
    style: [computed(() =>
      computedStyles.value ? {
        key: `custom-styles-${componentId}`,
        id: `custom-styles-${componentId}`,
        // Use textContent to apply simple sanitiszation server-side; client-side it will use the `element.textContent` setter
        textContent: computedStyles.value,
      } : {}),
    ],
  })

  return {
    componentId,
  }
}
