/**
 * Wraps a string with a prefix and suffix.
 *
 * @param {string} s - The string to wrap.
 * @param {string} prefix - The prefix to add before the string. Defaults to an empty string.
 * @param {string} suffix - The suffix to add after the string. Defaults to an empty string.
 * @returns The wrapped string.
 */
export const wrapString = (s: string, prefix: string = '', suffix: string = ''): string => {
  if (!s) {
    return ''
  }

  return `${prefix}${s}${suffix}`
}
